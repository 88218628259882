@import "src/App.scss";

.slogan {
  display: flex;
  background-color: $primary;
  color: white;
  margin-top: -4rem;
  justify-content: center;

  h2 {
    padding: 1rem;
    @extend %pagewidth;
  }
}

.skills {
  background-color: $black;
  color: white;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    justify-content: flex-startf;
  }

  p {
    @extend %pagewidth;
  }
}
