@import "src/App.scss";


.top-nav {
  display: flex;
  align-items: center;
  color: white;
  padding: 1rem 0;
  background-color: $primary;

  &__highlight {
    @extend %pagewidth;
  }
}

nav {
  background-color: $black;

  .row {
    color: black;
    @extend %pagewidth;

    ul {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      li {
        list-style: none;

        &:not(:nth-child(1)) {


          a {
            text-decoration: none;


            &.active, &:hover {
              background-color: $primary;
              padding: 1rem;
            }
          }
        }
      }
    }
  }
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 10px;
  background: $primary;
  transform-origin: 0;
}