// colours
$oww-red: #ff534a;
$aubergine: #460c37;
$yellow: #eee887;
$inactive: rgba(255, 255, 255, 0.25);
$inactive-typo: rgba(255, 255, 255, 0.5);
$jade-green: #4dac7d;
$landmarks: #f9a141;
$lightGreen: #a0d0a3;
$lightBlue: #88d2da;
$routes: #ec4544;
$verblijf: #f27a9c;
$black: #101010;
$wit: #ffffff;
$primary: #d40000;

// variables

$fontsize_base: 16px;

@function font-size($n, $fb: $fontsize_base, $unit: 1em) {
  @return calc($n / $fb) * $unit;
}

@function rem($n, $fb: $fontsize_base) {
  @return font-size($n, $fb, 1rem);
}

// FUNCTIONS AND REUSABLES
//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
        xs: 576px,
        sm: 780px,
        md: 992px,
        lg: 1224px,
        delf: 1681px,
        xl: 1700px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

$pagewidth: 1240px;
$pagewidthplus: ($pagewidth + 120px);

$responsive_switch--huge: 1440px;
$responsive_switch--bigger: 1366px;
$responsive_switch--menu: 1240px;
$responsive_switch--big: 1000px;
$responsive_switch--small: 700px;
$responsive_switch--mini: 480px;

%pagewidth {
  max-width: $pagewidth;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @include respond-below(md) {
    padding-right: 25px;
    padding-left: 25px;
  }
}

// if buttons with various colors turn into a mixin: @mixin button($color: $blue) {}
%button {
  margin: 0;
  padding: 0.7em 1em;
  transition: all 300ms ease;
  border: 0;
  border-radius: 0;
  // font-family: $font_body;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    transition: all 300ms ease;
  }
}

// turns a link into a clickable tile.
// set position: relative on parent container!
%linkTile {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-size: 0px;
  text-indent: -15000px;
  overflow: hidden;
}

// teaser
%teaser {
  // background-color: $white;
  box-sizing: border-box;
  margin: 1em 0;
  padding: 1em;

  h2 {
    a {
      text-decoration: none;
    }
  }
}

// oww
.row {
  display: flex;
}

.text-center {
  text-align: center;
}

@keyframes stripe-slide {
  0% {
    background-position: 0% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@mixin stripes($color) {
  &:after {
    content: "";
    display: block;
    right: 0;
    position: absolute;
    height: rem(5px);
    width: 100%;
    backface-visibility: hidden;
    background-image: repeating-linear-gradient(
                    127deg,
                    $color,
                    $color 0.2px,
                    transparent 2px,
                    transparent 4px
    );
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-size: 5px 5px;
    -webkit-animation: stripe-slide 12s infinite linear forwards;
    animation: stripe-slide 12s infinite linear forwards;
  }
}

body {
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  margin: 0;
}

a {
  color: white;
}