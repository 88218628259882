@import "src/App.scss";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.hero {
  background-color: $black;

  .row {
    align-items: center;
    min-height: 70vh;

    @include respond-below(md) {
      flex-direction: column;
      justify-content: center;
    }
    @extend %pagewidth;

    .col-left {
      .carousel {
        display: flex;
        align-items: center;

        .image {
          img {
            border-radius: rem(15px);
            width: 250px;
            @include respond-below(md) {
              width: 150px;
            }
          }
        }
      }
    }

    .col-right {

      &__description {
        border-radius: 15px;
        font-size: 2rem;
        color: white;
      }
    }
  }
}



