@import "src/App.scss";

.footer {
  background-color: #D30000;
  padding: 2rem;
  color: white;
  display: flex;
  justify-content: center;

  td {

    padding: .5rem;
  }
}

.footer__branding {
  text-align: center;
}