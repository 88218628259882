@import "src/App.scss";

.form {
  background: $black;

  &__request {
    @extend %pagewidth;
    padding: 2rem;
  }

  &__title {
    display: flex;
    flex-direction: column;
    position: relative;
    color: white;
    margin-top: 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 25px;
      height: 4px;
      background: $primary;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;

    &__item {
      flex: 1;

      &.left {
        display: flex;
        flex-direction: column;
      }

      &-error {
        color: $primary;
      }
    }
  }

}

.app-contact {
  margin-top: auto;
  color: #888;
}

.app-form-group {
  margin-bottom: 15px;

  .message {
    margin-top: rem(40px);
  }
}


.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.app-form-control {
  width: 100%;
  padding: rem(10px) 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  color: #ddd;
  font-size: 1rem;
  text-transform: uppercase;
  outline: none;

  &:focus {
    border-bottom-color: #ddd;

    &::placeholder {
      color: white;
    }
  }

  &::placeholder {
    color: #666;
  }
}


.app-form-button {
  border: none;
  color: white;
  background-color: $primary;
  padding: .5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  transition: all 300ms ease-in;

  &:hover {
    color: $primary;
    background-color: white;
    border-left: .3rem solid $primary;
  }
}

textarea {
  max-width: 100%;
}


@include respond-below(md) {
  .form__body {
    flex-direction: column;
  }

  .form__body__item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .screen-body-item {
    padding: 0;
  }
}
